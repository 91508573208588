<template>
  <div>
    <Loader
      v-if="loaderEnabled"
      v-bind:logo="loaderLogo"
    ></Loader>
    <KTCodePreview v-bind:title="'Danh sách cài đặt'">
      <template v-slot:preview>
        <b-row>
          <div class="titleBoxSt">
            <h2 class="titleItemSt">Cài đặt đơn hàng</h2>
          </div>
          <div class="setting-day">
            <b-row>
              <div class="setting-holding-day col-md-8 col-sm-8 col-xs-8">
                <i class="far fa-calendar-alt icon-color ml-4"></i>
                <div class="setting-content">
                  <p class="mb-0 font-weight-bolder">
                    Cài đặt số ngày tạm giữ đơn hàng
                  </p>
                  <p class="mb-2 des">
                    Số ngày tạm giữ đơn hàng. Mặc định là 2 ngày, tối đa 30
                    ngày.
                    <span class="text-success">({{ numberHoldingDay }} ngày)</span>
                  </p>
                </div>
                <b-form-input
                  v-show="showEditTextBox"
                  v-model="numberHoldingDay"
                  placeholder="Nhập số ngày"
                  class="input-holding-day ml-5 text-right"
                  size="sm"
                ></b-form-input>
              </div>

              <div class="action col-md-4 col-sm-4 col-xs-4 justify-content-center">
                <b-button
                  v-show="showEditButton"
                  @click="handleEditClick()"
                  type="button"
                  class="editButton btn font-weight-bolder btn-sm"
                >
                  <i
                    style="font-size: 1rem"
                    class="fas fa-edit"
                  ></i>Sửa
                </b-button>

                <b-button
                  v-show="showCancelButton"
                  @click="handleCancelClick()"
                  type="button"
                  class="editButton btn font-weight-bolder btn-sm ml-2"
                >
                  <i
                    style="font-size: 1rem"
                    class="fas fa-times"
                  ></i>Đóng
                </b-button>

                <b-button
                  v-show="showSaveButton"
                  @click="handleSaveClick()"
                  type="button"
                  class="editButton btn btn-sm font-weight-bolder"
                >
                  <i
                    style="font-size: 1rem"
                    class="fas fa-save"
                  ></i>Lưu
                </b-button>
              </div>
            </b-row>
          </div>

          <div class="setting-day">
            <b-row>
              <div class="setting-holding-day col-md-8 col-sm-8 col-xs-8">
                <i class="far fa-calendar-alt icon-color ml-4"></i>
                <div class="setting-content">
                  <p class="mb-0 font-weight-bolder">
                    Cài đặt số ngày sửa đơn hàng:
                  </p>
                  <p class="mb-2 des">
                    Cài đặt số ngày cho phép nhân viên sửa đơn hàng. VD 10 ngày,
                    20 ngày, 30 ngày...
                    <span class="text-success">({{ numberUpdateOrder }} ngày)</span>
                  </p>
                </div>
                <b-form-input
                  v-show="showNumUpdateOrderTextBox"
                  v-model="numberUpdateOrder"
                  placeholder="Nhập số ngày"
                  class="input-holding-day ml-5 text-right"
                  size="sm"
                ></b-form-input>
              </div>

              <div class="action col-md-4 col-sm-4 col-xs-4 justify-content-center">
                <b-button
                  v-show="showEditUpdateOrderButton"
                  @click="handleEditNumUpdateClick()"
                  type="button"
                  class="editButton btn font-weight-bolder btn-sm"
                >
                  <i
                    style="font-size: 1rem"
                    class="fas fa-edit"
                  ></i>Sửa
                </b-button>

                <b-button
                  v-show="showCancelUpdateOrderButton"
                  @click="handleCancelUpdateOrderClick()"
                  type="button"
                  class="editButton btn font-weight-bolder btn-sm ml-2"
                >
                  <i
                    style="font-size: 1rem"
                    class="fas fa-times"
                  ></i>Đóng
                </b-button>

                <b-button
                  v-show="showSaveUpdateOrderButton"
                  @click="handleSaveNumUpdateClick()"
                  type="button"
                  class="editButton btn btn-sm font-weight-bolder"
                >
                  <i
                    style="font-size: 1rem"
                    class="fas fa-save"
                  ></i>Lưu
                </b-button>
              </div>
            </b-row>
          </div>
        </b-row>
        <b-row>
          <div class="titleBoxSt">
            <h2 class="titleItemSt">Cài đặt bán hàng</h2>
          </div>
          <div class="setting-day">
            <b-row>
              <div class="setting-holding-day col-md-8 col-sm-8 col-xs-8">
                <i class="far fa-calendar-alt icon-color ml-4"></i>
                <div class="setting-content">
                  <p class="mb-0 font-weight-bolder">
                    Cài đặt số ngày được phép trả hàng
                  </p>
                  <p class="mb-2 des">
                    Số ngày được phép trả hàng. Mặc định là 33 ngày.
                    <span class="text-success">({{ numberReturnDay }} ngày)</span>
                  </p>
                </div>
                <b-form-input
                  v-show="showNumReturnBillTextBox"
                  v-model="numberReturnDay"
                  placeholder="Nhập số ngày"
                  class="input-holding-day ml-5 text-right"
                  size="sm"
                ></b-form-input>
              </div>

              <div class="action col-md-4 col-sm-4 col-xs-4 justify-content-center">
                <b-button
                  v-show="showEditReturBillButton"
                  @click="handleEditReturnBillClick()"
                  type="button"
                  class="editButton btn font-weight-bolder btn-sm"
                >
                  <i
                    style="font-size: 1rem"
                    class="fas fa-edit"
                  ></i>Sửa
                </b-button>

                <b-button
                  v-show="showCancelReturBillButton"
                  @click="handleCancelReturnBillClick()"
                  type="button"
                  class="editButton btn font-weight-bolder btn-sm ml-2"
                >
                  <i
                    style="font-size: 1rem"
                    class="fas fa-times"
                  ></i>Đóng
                </b-button>

                <b-button
                  v-show="showSaveReturnBillButton"
                  @click="handleSaveReturnBillClick()"
                  type="button"
                  class="editButton btn btn-sm font-weight-bolder"
                >
                  <i
                    style="font-size: 1rem"
                    class="fas fa-save"
                  ></i>Lưu
                </b-button>
              </div>
            </b-row>
          </div>

          <div class="setting-day">
            <b-row>
              <div class="setting-holding-day col-md-8 col-sm-8 col-xs-8">
                <i class="far fa-calendar-alt icon-color ml-4"></i>
                <div class="setting-content">
                  <p class="mb-0 font-weight-bolder">
                    Cài đặt số ngày sửa hóa đơn:
                  </p>
                  <p class="mb-2 des">
                    Cài đặt số ngày cho phép nhân viên sửa hóa đơn. VD 10 ngày,
                    20 ngày, 30 ngày...
                    <span class="text-success">({{ numberUpdateBill }} ngày)</span>
                  </p>
                </div>
                <b-form-input
                  v-show="showNumUpdateBillTextBox"
                  v-model="numberUpdateBill"
                  placeholder="Nhập số ngày"
                  class="input-holding-day ml-5 text-right"
                  size="sm"
                ></b-form-input>
              </div>

              <div class="action col-md-4 col-sm-4 col-xs-4 justify-content-center">
                <b-button
                  v-show="showEditUpdateBillButton"
                  @click="handleEditUpdateBillClick()"
                  type="button"
                  class="editButton btn font-weight-bolder btn-sm"
                >
                  <i
                    style="font-size: 1rem"
                    class="fas fa-edit"
                  ></i>Sửa
                </b-button>

                <b-button
                  v-show="showCancelUpdateBillButton"
                  @click="handleCancelUpdateBillClick()"
                  type="button"
                  class="editButton btn font-weight-bolder btn-sm ml-2"
                >
                  <i
                    style="font-size: 1rem"
                    class="fas fa-times"
                  ></i>Đóng
                </b-button>

                <b-button
                  v-show="showSaveUpdateBillButton"
                  @click="handleSaveUpdateBillClick()"
                  type="button"
                  class="editButton btn btn-sm font-weight-bolder"
                >
                  <i
                    style="font-size: 1rem"
                    class="fas fa-save"
                  ></i>Lưu
                </b-button>
              </div>
            </b-row>
          </div>
          <div class="setting-day">
            <b-row>
              <div class="setting-holding-day col-md-8 col-sm-8 col-xs-8">
                <i class="far fa-calendar-alt icon-color ml-4"></i>
                <div class="setting-content">
                  <p class="mb-0 font-weight-bolder">
                    Cài đặt ngày khóa dữ liệu hóa đơn:
                  </p>
                  <p class="mb-2 des">
                    Sau ngày này của tháng, dữ liệu hóa đơn sẽ không được chỉnh
                    sửa. VD ngày 7, ngày 9, ... Để ngày 0 nếu không khóa dữ liệu.
                    <span class="text-success">(ngày {{ dateBillBlock }} hàng tháng)</span>
                  </p>
                </div>
                <b-form-input
                  v-show="showEditBillBlockBox"
                  v-model="dateBillBlock"
                  placeholder="Nhập ngày trong tháng"
                  class="input-holding-day ml-5 text-right"
                  size="sm"
                ></b-form-input>
              </div>

              <div class="action col-md-4 col-sm-4 col-xs-4 justify-content-center">
                <b-button
                  v-show="showEditBillBlockButton"
                  @click="handleEditBillBlockClick()"
                  type="button"
                  class="editButton btn font-weight-bolder btn-sm"
                >
                  <i
                    style="font-size: 1rem"
                    class="fas fa-edit"
                  ></i>Sửa
                </b-button>

                <b-button
                  v-show="showCancelBillBlock"
                  @click="handleCancelBillBlockClick()"
                  type="button"
                  class="editButton btn font-weight-bolder btn-sm ml-2"
                >
                  <i
                    style="font-size: 1rem"
                    class="fas fa-times"
                  ></i>Đóng
                </b-button>

                <b-button
                  v-show="showSaveBillBlock"
                  @click="handleSaveBillBlockClick()"
                  type="button"
                  class="editButton btn btn-sm font-weight-bolder"
                >
                  <i
                    style="font-size: 1rem"
                    class="fas fa-save"
                  ></i>Lưu
                </b-button>
              </div>
            </b-row>
          </div>
          <!-- Thời gian cho mượn máy -->
          <div class="setting-day">
            <b-row>
              <div class="setting-holding-day col-md-8 col-sm-8 col-xs-8">
                <i class="far fa-calendar-alt icon-color ml-4"></i>
                <div class="setting-content">
                  <p class="mb-0 font-weight-bolder">
                    Cài đặt số ngày nhắc nhở trả máy:
                  </p>
                  <p class="mb-2 des">
                    Số ngày nhắc nhắc khách hàng trả máy. Tối đa là 30 ngày.
                    <span class="text-success">(nhắc nhở trước {{ numberRemindDay }} ngày tính từ thời gian mượn máy)</span>
                  </p>
                </div>
                <b-form-input
                  v-show="isEditNumRemindTxt"
                  v-model="numberRemindDay"
                  placeholder="Nhập số ngày"
                  class="input-holding-day ml-5 text-right"
                  size="sm"
                  type="number"
                ></b-form-input>
              </div>

              <div class="action col-md-4 col-sm-4 col-xs-4 justify-content-center">
                <b-button
                  v-show="isEditNumRemindButton"
                  @click="handleEditRemindDate()"
                  type="button"
                  class="editButton btn font-weight-bolder btn-sm"
                >
                  <i
                    style="font-size: 1rem"
                    class="fas fa-edit"
                  ></i>Sửa
                </b-button>

                <b-button
                  v-show="isCancelNumRemind"
                  @click="handleCancelRemindDate()"
                  type="button"
                  class="editButton btn font-weight-bolder btn-sm ml-2"
                >
                  <i
                    style="font-size: 1rem"
                    class="fas fa-times"
                  ></i>Đóng
                </b-button>

                <b-button
                  v-show="isSaveNumRemind"
                  @click="handleSaveRemindDate()"
                  type="button"
                  class="editButton btn btn-sm font-weight-bolder"
                >
                  <i
                    style="font-size: 1rem"
                    class="fas fa-save"
                  ></i>Lưu
                </b-button>
              </div>
            </b-row>
          </div>

        </b-row>
        <b-row>
          <div class="titleBoxSt">
            <h2 class="titleItemSt">Cài đặt kế toán</h2>
          </div>
          <div class="setting-day">
            <b-row>
              <div class="setting-holding-day col-md-8 col-sm-8 col-xs-8">
                <i class="fas fa-file-invoice-dollar ml-4"></i>
                <div class="setting-content">
                  <p class="mb-0 font-weight-bolder">Cài đặt phí quẹt thẻ</p>
                  <p class="mb-2 des">
                    Phí quẹt thẻ thu của khách hàng khi thanh toán bằng quẹt
                    thẻ(%). Mặc định là 0.5%, tối đa 100%.
                    <span class="text-success">({{ creditCardFee }} %)</span>
                  </p>
                </div>
                <b-form-input
                  v-show="showEditCreditCardFeeBox"
                  v-model="creditCardFee"
                  placeholder="Nhập tỉ lệ thu phí quẹt thẻ"
                  class="input-holding-day ml-5 text-right"
                  size="sm"
                ></b-form-input>
              </div>

              <div class="action col-md-4 col-sm-4 col-xs-4 justify-content-center">
                <b-button
                  v-show="showEditCreditCardFeeButton"
                  @click="handleEditCreditClick()"
                  type="button"
                  class="editButton btn font-weight-bolder btn-sm"
                >
                  <i
                    style="font-size: 1rem"
                    class="fas fa-edit"
                  ></i>Sửa
                </b-button>

                <b-button
                  v-show="showCancelCreditCardFee"
                  @click="handleCancelCreditClick()"
                  type="button"
                  class="editButton btn font-weight-bolder btn-sm ml-2"
                >
                  <i
                    style="font-size: 1rem"
                    class="fas fa-times"
                  ></i>Đóng
                </b-button>

                <b-button
                  v-show="showSaveCreditCardFee"
                  @click="handleSaveCreditClick()"
                  type="button"
                  class="editButton btn btn-sm font-weight-bolder"
                >
                  <i
                    style="font-size: 1rem"
                    class="fas fa-save"
                  ></i>Lưu
                </b-button>
              </div>
            </b-row>
          </div>

          <div class="setting-day">
            <b-row>
              <div class="setting-holding-day col-md-8 col-sm-8 col-xs-8">
                <i class="fas fa-file-invoice-dollar ml-4"></i>
                <div class="setting-content">
                  <p class="mb-0 font-weight-bolder">
                    Cài đặt phí thu hộ tiền trả góp:
                  </p>
                  <p class="mb-2 des">
                    Cài đặt tỉ lệ phí thu hộ tiền trả góp(%). Mặc định là 0.5%,
                    tối đa 100%.
                    <span class="text-success">({{ installmentFee }} %)</span>
                  </p>
                </div>
                <b-form-input
                  v-show="showEditInstallmentFeeBox"
                  v-model="installmentFee"
                  placeholder="Nhập phí thu hộ tiền trả góp"
                  class="input-holding-day ml-5 text-right"
                  size="sm"
                ></b-form-input>
              </div>

              <div class="action col-md-4 col-sm-4 col-xs-4 justify-content-center">
                <b-button
                  v-show="showEditInstallmentFeeButton"
                  @click="handleEditInstallmentClick()"
                  type="button"
                  class="editButton btn font-weight-bolder btn-sm"
                >
                  <i
                    style="font-size: 1rem"
                    class="fas fa-edit"
                  ></i>Sửa
                </b-button>

                <b-button
                  v-show="showCancelInstallmentFee"
                  @click="handleCancelInstallmentClick()"
                  type="button"
                  class="editButton btn font-weight-bolder btn-sm ml-2"
                >
                  <i
                    style="font-size: 1rem"
                    class="fas fa-times"
                  ></i>Đóng
                </b-button>

                <b-button
                  v-show="showSaveInstallmentFee"
                  @click="handleSaveInstallmentClick()"
                  type="button"
                  class="editButton btn btn-sm font-weight-bolder"
                >
                  <i
                    style="font-size: 1rem"
                    class="fas fa-save"
                  ></i>Lưu
                </b-button>
              </div>
            </b-row>
          </div>
          <div class="setting-day">
            <b-row>
              <div class="setting-holding-day col-md-8 col-sm-8 col-xs-8">
                <i class="fas fa-file-invoice-dollar ml-4"></i>
                <div class="setting-content">
                  <p class="mb-0 font-weight-bolder">
                    Cài đặt số ngày gửi hoá đơn đỏ:
                  </p>
                  <p class="mb-2 des">
                    Cài đặt số ngày cần gửi hoá đơn đỏ...
                    <span class="text-success">({{ vatInvoice }} ngày)</span>
                  </p>
                </div>
                <b-form-input
                  v-show="showEditVatInvoiceFeeBox"
                  v-model="vatInvoice"
                  placeholder="Nhập ngày gửi hoá đơn đỏ"
                  class="input-holding-day ml-5 text-right"
                  size="sm"
                ></b-form-input>
              </div>

              <div class="action col-md-4 col-sm-4 col-xs-4 justify-content-center">
                <b-button
                  v-show="showEditVatInvoiceButton"
                  @click="handleEditVatInvoiceClick()"
                  type="button"
                  class="editButton btn font-weight-bolder btn-sm"
                >
                  <i
                    style="font-size: 1rem"
                    class="fas fa-edit"
                  ></i>Sửa
                </b-button>

                <b-button
                  v-show="showCancelVatInvoice"
                  @click="handleCancelVatInvoiceClick()"
                  type="button"
                  class="editButton btn font-weight-bolder btn-sm ml-2"
                >
                  <i
                    style="font-size: 1rem"
                    class="fas fa-times"
                  ></i>Đóng
                </b-button>

                <b-button
                  v-show="showSaveVatInvoice"
                  @click="handleSaveVatInvoiceClick()"
                  type="button"
                  class="editButton btn btn-sm font-weight-bolder"
                >
                  <i
                    style="font-size: 1rem"
                    class="fas fa-save"
                  ></i>Lưu
                </b-button>
              </div>
            </b-row>
          </div>
        </b-row>
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';

export default {
  data() {
    return {
      showNumUpdateOrderTextBox: false,
      numberUpdateOrder: 0,
      showEditUpdateOrderButton: true,
      showCancelUpdateOrderButton: false,
      showSaveUpdateOrderButton: false,
      numberReturnDay: 0,
      numberUpdateBill: 0,
      showNumUpdateBillTextBox: false,
      showEditUpdateBillButton: true,
      showCancelUpdateBillButton: false,
      showSaveUpdateBillButton: false,
      showEditTextBox: false,
      numberHoldingDay: 0,
      showEditButton: true,
      showCancelButton: false,
      showSaveButton: false,
      loaderEnabled: false,
      id: '',
      creditCardFee: 0,
      installmentFee: 0,
      showSaveCreditCardFee: false,
      showSaveInstallmentFee: false,
      showCancelCreditCardFee: false,
      showCancelInstallmentFee: false,
      showEditCreditCardFeeButton: true,
      showEditInstallmentFeeButton: true,
      showEditCreditCardFeeBox: false,
      showEditInstallmentFeeBox: false,
      showNumReturnBillTextBox: false,
      showEditReturBillButton: true,
      showCancelReturBillButton: false,
      showSaveReturnBillButton: false,
      showEditVatInvoiceFeeBox: false,
      vatInvoice: 0,
      showEditVatInvoiceButton: true,
      showCancelVatInvoice: false,
      showSaveVatInvoice: false,
      showEditBillBlockBox: false,
      dateBillBlock: 0,
      showEditBillBlockButton: true,
      showCancelBillBlock: false,
      showSaveBillBlock: false,
      isEditNumRemindTxt: false,
      numberRemindDay: 0,
      isEditNumRemindButton: true,
      isCancelNumRemind: false,
      isSaveNumRemind: false,
    };
  },
  components: {
    KTCodePreview,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Cài đặt', route: 'settings' },
      { title: 'Danh sách cài đặt' },
    ]);
  },
  created() {
    this.getSetting();
  },
  methods: {
    getSetting: async function () {
      await ApiService.query('settings').then((res) => {
        const {
          id,
          numberHoldingDay,
          numberUpdatingDay,
          creditFee,
          installmentFee,
          numberReturnDay,
          numberUpdateBill,
          numberVatInvoice,
          dateBillBlock,
          numberRemindDay,
        } = res.data.data;
        this.id = id;
        this.numberHoldingDay = numberHoldingDay;
        this.numberUpdateOrder = numberUpdatingDay;
        this.creditCardFee = creditFee;
        this.installmentFee = installmentFee;
        this.numberReturnDay = numberReturnDay;
        this.numberUpdateBill = numberUpdateBill;
        this.vatInvoice = numberVatInvoice;
        this.dateBillBlock = dateBillBlock ? dateBillBlock : 0;
        this.numberRemindDay = numberRemindDay;
      });
    },
    updateNumberEditingOrder: async function () {
      const params = {
        id: this.id,
        numberUpdatingDay: this.numberUpdateOrder,
      };
      await ApiService.put('settings/updateNumberUpdatingDay', params).then(
        (response) => {
          const { status, message } = response.data;
          if (status === 1) {
            this.getSetting();
            this.makeToastSuccess(message);
          } else {
            this.makeToastFaile(message);
          }
        },
      );
    },
    updateSetting: async function () {
      const params = {
        id: this.id,
        numberHoldingDay: this.numberHoldingDay,
      };
      await ApiService.put('settings', params).then((response) => {
        const { status, message } = response.data;
        if (status === 1) {
          this.getSetting();
          this.makeToastSuccess(message);
        } else {
          this.makeToastFaile(message);
        }
      });
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    handleEditRemindDate() {
      this.isEditNumRemindButton = false;
      this.isCancelNumRemind = true;
      this.isSaveNumRemind = true;
      this.isEditNumRemindTxt = true;
    },
    handleCancelRemindDate() {
      this.isEditNumRemindButton = true;
      this.isCancelNumRemind = false;
      this.isSaveNumRemind = false;
      this.isEditNumRemindTxt = false;
    },
    handleSaveRemindDate() {
      this.isEditNumRemindButton = true;
      this.isCancelNumRemind = false;
      this.isSaveNumRemind = false;
      this.isEditNumRemindTxt = false;
      this.updateRemindDate();
    },
    handleEditNumUpdateClick() {
      this.showEditUpdateOrderButton = false;
      this.showCancelUpdateOrderButton = true;
      this.showSaveUpdateOrderButton = true;
      this.showNumUpdateOrderTextBox = true;
    },
    handleSaveNumUpdateClick() {
      this.showCancelUpdateOrderButton = false;
      this.showSaveUpdateOrderButton = false;
      this.showEditUpdateOrderButton = true;
      this.showNumUpdateOrderTextBox = false;
      this.updateNumberEditingOrder();
    },
    handleCancelUpdateOrderClick() {
      this.showCancelUpdateOrderButton = false;
      this.showSaveUpdateOrderButton = false;
      this.showEditUpdateOrderButton = true;
      this.showNumUpdateOrderTextBox = false;
    },
    handleEditClick() {
      this.showEditButton = false;
      this.showCancelButton = true;
      this.showSaveButton = true;
      this.showEditTextBox = true;
    },
    handleSaveClick() {
      this.showCancelButton = false;
      this.showSaveButton = false;
      this.showEditButton = true;
      this.showEditTextBox = false;
      this.updateSetting();
    },
    handleCancelClick() {
      this.showCancelButton = false;
      this.showSaveButton = false;
      this.showEditButton = true;
      this.showEditTextBox = false;
    },
    handleEditCreditClick() {
      this.showEditCreditCardFeeButton = false;
      this.showCancelCreditCardFee = true;
      this.showSaveCreditCardFee = true;
      this.showEditCreditCardFeeBox = true;
    },
    handleSaveCreditClick() {
      this.showCancelCreditCardFee = false;
      this.showSaveCreditCardFee = false;
      this.showEditCreditCardFeeButton = true;
      this.showEditCreditCardFeeBox = false;
      this.updateAccountantsSetting();
    },
    handleCancelCreditClick() {
      this.showCancelCreditCardFee = false;
      this.showSaveCreditCardFee = false;
      this.showEditCreditCardFeeButton = true;
      this.showEditCreditCardFeeBox = false;
    },
    handleEditInstallmentClick() {
      this.showEditInstallmentFeeButton = false;
      this.showCancelInstallmentFee = true;
      this.showSaveInstallmentFee = true;
      this.showEditInstallmentFeeBox = true;
    },
    handleSaveInstallmentClick() {
      this.showCancelInstallmentFee = false;
      this.showSaveInstallmentFee = false;
      this.showEditInstallmentFeeButton = true;
      this.showEditInstallmentFeeBox = false;
      this.updateAccountantsSetting();
    },
    handleCancelInstallmentClick() {
      this.showCancelInstallmentFee = false;
      this.showSaveInstallmentFee = false;
      this.showEditInstallmentFeeButton = true;
      this.showEditInstallmentFeeBox = false;
    },
    handleEditVatInvoiceClick() {
      this.showCancelVatInvoice = true;
      this.showSaveVatInvoice = true;
      this.showEditVatInvoiceButton = false;
      this.showEditVatInvoiceFeeBox = true;
    },
    handleCancelVatInvoiceClick() {
      this.showCancelVatInvoice = false;
      this.showSaveVatInvoice = false;
      this.showEditVatInvoiceButton = true;
      this.showEditVatInvoiceFeeBox = false;
    },
    handleSaveVatInvoiceClick() {
      this.showCancelVatInvoice = false;
      this.showSaveVatInvoice = false;
      this.showEditVatInvoiceButton = true;
      this.showEditVatInvoiceFeeBox = false;
      this.updateAccountantsSetting();
    },
    updateAccountantsSetting: async function () {
      const params = {
        id: this.id,
        creditFee: this.creditCardFee,
        installmentFee: this.installmentFee,
        numberVatInvoice: this.vatInvoice,
      };
      await ApiService.put('settings/accountantSetting', params).then(
        (response) => {
          const { status, message } = response.data;
          if (status === 1) {
            this.getSetting();
            this.makeToastSuccess(message);
          } else {
            this.makeToastFaile(message);
          }
        },
      );
    },
    handleEditReturnBillClick() {
      this.showEditReturBillButton = false;
      this.showCancelReturBillButton = true;
      this.showSaveReturnBillButton = true;
      this.showNumReturnBillTextBox = true;
    },
    handleSaveReturnBillClick() {
      this.showCancelReturBillButton = false;
      this.showSaveReturnBillButton = false;
      this.showEditReturBillButton = true;
      this.showNumReturnBillTextBox = false;
      this.updateBillSetting();
    },
    handleCancelReturnBillClick() {
      this.showCancelReturBillButton = false;
      this.showSaveReturnBillButton = false;
      this.showEditReturBillButton = true;
      this.showNumReturnBillTextBox = false;
    },
    handleEditUpdateBillClick() {
      this.showEditUpdateBillButton = false;
      this.showCancelUpdateBillButton = true;
      this.showSaveUpdateBillButton = true;
      this.showNumUpdateBillTextBox = true;
    },
    handleSaveUpdateBillClick() {
      this.showCancelUpdateBillButton = false;
      this.showSaveUpdateBillButton = false;
      this.showEditUpdateBillButton = true;
      this.showNumUpdateBillTextBox = false;
      this.updateBillSetting();
    },
    handleCancelUpdateBillClick() {
      this.showCancelUpdateBillButton = false;
      this.showSaveUpdateBillButton = false;
      this.showEditUpdateBillButton = true;
      this.showNumUpdateBillTextBox = false;
    },
    updateRemindDate: async function () {
      if (this.numberRemindDay < 1) {
        this.makeToastFaile('Số ngày nhắc nhở phải lớn hơn 1');
        return;
      }
      if (this.numberRemindDay > 30) {
        this.makeToastFaile('Số ngày nhắc nhở không được vượt quá 30 ngày');
        return;
      }
      const params = {
        id: this.id,
        numberRemindDay: this.numberRemindDay,
      };
      await ApiService.put('settings/upsertSetting', params).then(
        (response) => {
          const { status, message } = response.data;
          if (status === 1) {
            this.getSetting();
            this.makeToastSuccess(message);
          } else {
            this.makeToastFaile(message);
          }
        },
      );
    },
    updateBillSetting: async function () {
      const params = {
        id: this.id,
        numberReturnDay: this.numberReturnDay,
        numberUpdateBill: this.numberUpdateBill,
        dateBillBlock: this.dateBillBlock,
      };
      await ApiService.put('settings/billSetting', params).then((response) => {
        const { status, message } = response.data;
        if (status === 1) {
          this.getSetting();
          this.makeToastSuccess(message);
        } else {
          this.makeToastFaile(message);
        }
      });
    },
    handleEditBillBlockClick() {
      this.showCancelBillBlock = true;
      this.showSaveBillBlock = true;
      this.showEditBillBlockButton = false;
      this.showEditBillBlockBox = true;
    },
    handleCancelBillBlockClick() {
      this.showCancelBillBlock = false;
      this.showSaveBillBlock = false;
      this.showEditBillBlockButton = true;
      this.showEditBillBlockBox = false;
    },
    handleSaveBillBlockClick() {
      this.showCancelBillBlock = false;
      this.showSaveBillBlock = false;
      this.showEditBillBlockButton = true;
      this.showEditBillBlockBox = false;
      this.updateBillSetting();
    },
  },
};
</script>

<style scoped>
.action {
  float: right;
}

.setting-day {
  border-bottom: 1px solid #dddfe2;
  width: 100%;
}

.titleBoxSt {
  border-bottom: 1px solid #dddfe2;
  background: #f6f7f9;
  overflow: hidden;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  padding-left: 14px;
}
.titleItemSt {
  color: #1d2129;
  margin: 0;
  padding: 2px 0 0;
  font-size: 12px;
  font-weight: 600;
}

.setting-holding-day {
  -webkit-align-items: center;
  display: flex;
  margin-top: 5px;
}

.setting-content {
  margin-left: 10px;
}

.des {
  color: #696868;
}

.editButton {
  float: right;
}

.action {
  margin-top: 5px;
}

.input-holding-day {
  width: 12%;
}

.icon-color {
  color: #464e5f;
}
</style>
